<template>
  <div class="main">
    <!-- 打印 -->
    <div class="print">
      <el-button type="primary" :icon="MessageBox" @click="printpage"
        >打印页面或保存PDF</el-button
      >
    </div>

    <div class="content" id="inner">
      <div class="rp-logo">
        <el-image
          style="width: 300px; height: 65px"
          src="/img/common/logo.png"
          fit="fill"
        />
      </div>
      <div class="rp-list" v-for="(item, key) in fhsj" :key="key">
        <div class="rp-list-title">《{{ item.Title }}》</div>
        <div class="rp-list-main">
          <div class="rp-list-main-img">
            <el-image
              style="width: 138px; height: 178px"
              :src="proxy.$imgurl + '/qikanfmpic/' + item.pycode + '.jpg'"
              fit="fill"
            />
          </div>
          <div class="rp-list-main-wz">
            <div class="rp-list-main-wz-title">一、投稿信息</div>
            <div class="rp-list-main-wz-list">
              <div class="rp-list-main-wz-list-title">主编：</div>
              <div class="rp-list-main-wz-list-nr">{{ item.zhubian }}</div>
            </div>
            <div class="rp-list-main-wz-list">
              <div class="rp-list-main-wz-list-title">电话：</div>
              <div class="rp-list-main-wz-list-nr">{{ item.tel }}</div>
            </div>
            <div class="rp-list-main-wz-list">
              <div class="rp-list-main-wz-list-title">邮箱：</div>
              <div class="rp-list-main-wz-list-nr">{{ item.dzyx }}</div>
            </div>
            <div class="rp-list-main-wz-list">
              <div class="rp-list-main-wz-list-title">邮编：</div>
              <div class="rp-list-main-wz-list-nr">{{ item.yzbm }}</div>
            </div>
            <div class="rp-list-main-wz-list">
              <div class="rp-list-main-wz-list-title">地址：</div>
              <div class="rp-list-main-wz-list-nr">{{ item.address }}</div>
            </div>
            <div class="rp-list-main-wz-list" v-if="item.kz3 && item.kz3!=''">
              <div class="rp-list-main-wz-list-title">投稿网址：</div>
              <div class="rp-list-main-wz-list-nr"><a :href="item.kz3" target="_blank">{{ item.kz3 }}</a></div>
            </div>
          </div>
        </div>
        <div class="rp-list-main-wz rp-list-main-wz-line">
          <div class="rp-list-main-wz-title">二、关键信息</div>
          <div class="rp-list-main-wz-list rp-list-main-wz-list-50">
            <div class="rp-list-main-wz-list-title">期刊情况：</div>
            <div class="rp-list-main-wz-list-nr">
              {{ item.CorePeriodical }}
            </div>
          </div>
          <div class="rp-list-main-wz-list rp-list-main-wz-list-50">
            <div class="rp-list-main-wz-list-title">收录情况：</div>
            <div class="rp-list-main-wz-list-nr">
              <span v-for="(e,i) in item.shouluqingkuang" :key="i">
                <a v-if="e.split('：')[1]!=''" target="_blank" style="margin:0 10px 0 0" :href="e.split('：')[1]">{{e.split('：')[0]}}</a>
              </span>
            </div>
          </div>
          <div class="rp-list-main-wz-list rp-list-main-wz-list-50">
            <div class="rp-list-main-wz-list-title">影响因子：</div>
            <div class="rp-list-main-wz-list-nr">{{ item.yxyz }}</div>
          </div>
          <div class="rp-list-main-wz-list rp-list-main-wz-list-50">
            <div class="rp-list-main-wz-list-title">基金论文：</div>
            <div class="rp-list-main-wz-list-nr">
              {{ ((item.jjlwl / item.wxl) * 100).toFixed(2) }}%
            </div>
          </div>
        </div>
        <div class="rp-list-main-wz rp-list-main-wz-line">
          <div class="rp-list-main-wz-title">三、基本属性</div>
          <div class="rp-list-main-wz-list rp-list-main-wz-list-50">
            <div class="rp-list-main-wz-list-title">出版周期：</div>
            <div class="rp-list-main-wz-list-nr">{{ item.cbzq }}</div>
          </div>
          <div class="rp-list-main-wz-list rp-list-main-wz-list-50">
            <div class="rp-list-main-wz-list-title">语种：</div>
            <div class="rp-list-main-wz-list-nr">{{ item.yuzhong }}</div>
          </div>
          <div class="rp-list-main-wz-list rp-list-main-wz-list-50">
            <div class="rp-list-main-wz-list-title">国际刊号：</div>
            <div class="rp-list-main-wz-list-nr">{{ item.gjkh }}</div>
          </div>
          <div class="rp-list-main-wz-list rp-list-main-wz-list-50">
            <div class="rp-list-main-wz-list-title">国内刊号：</div>
            <div class="rp-list-main-wz-list-nr">{{ item.gnkh }}</div>
          </div>
          <div class="rp-list-main-wz-list">
            <div class="rp-list-main-wz-list-title">主管单位：</div>
            <div class="rp-list-main-wz-list-nr">{{ item.zgorg }}</div>
          </div>
          <div class="rp-list-main-wz-list">
            <div class="rp-list-main-wz-list-title">主办单位：</div>
            <div class="rp-list-main-wz-list-nr">{{ item.zborg }}</div>
          </div>
          <div class="rp-list-main-wz-list">
            <div class="rp-list-main-wz-list-title">主要栏目：</div>
            <div class="rp-list-main-wz-list-nr">{{ item.zylm }}</div>
          </div>
          <div class="rp-list-main-wz-list" v-if="item.Award && item.Award!=''">
            <div class="rp-list-main-wz-list-title">获奖情况：</div>
            <div class="rp-list-main-wz-list-nr">{{ item.Award }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
const { proxy } = getCurrentInstance();

const router = useRouter();
let fhsj = reactive([]);

const getdetail = () => {
  get("/Journal/GetContrastBynid", {
    id: router.currentRoute.value.query.id,
  }).then((res) => {
    fhsj.push(...res.data);
    fhsj.forEach(e=>{
      e.shouluqingkuang=e.slqkdet.split(';\n')
    })
    // console.log(res);
  });
};
getdetail();

// 打印
const printpage = () => {
  //获取页面内容
  let html = document.body.innerHTML;
  //获得id里的内容
  let printHtml = document.getElementById("inner").innerHTML;
  //需要打印的内容渲染在页面上
  document.body.innerHTML = printHtml;
  //调用浏览器打印方法
  window.print();
  //打印完成或取消打印返回之前的页面内容
  document.body.innerHTML = html;
  // 刷新页面否则注册事件全部失效
  window.location.reload();
};
</script>

<style scoped>
.main {
  position: relative;
  background: #fff;
}
.content {
  width: 800px;
  height: auto;
  padding: 30px;
  box-sizing: border-box;
  background: #fff;
  margin: auto;
  border: 20px solid #fafafa;
}
.rp-list {
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
}
.rp-list-title {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 8px;
}
.rp-list-main {
  padding-bottom: 5px;
}
.rp-list-main:after {
  content: "";
  display: table;
  clear: both;
}
.rp-list-main-img {
  width: 140px;
  height: 180px;
  box-sizing: border-box;
  float: right;
  border: 1px solid #ddd;
}
.rp-list-main-wz {
  width: calc(100% - 150px);
  height: auto;
  float: left;
  font-size: 14px;
  padding: 0 20px 0 0;
  box-sizing: border-box;
}
.rp-list-main-wz:after {
  content: "";
  display: table;
  clear: both;
}
.rp-list-main-wz-list {
  padding-bottom: 5px;
  float: left;
  width: 100%;
}
.rp-list-main-wz-list:after {
  content: "";
  display: table;
  clear: both;
}
.rp-list-main-wz-list-title {
  width: 90px;
  float: left;
  font-weight: bold;
  text-align: justify;
}
.rp-list-main-wz-list-nr {
  float: left;
  width: calc(100% - 90px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rp-list-main-wz-title {
  float: left;
  color: #3889e1;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
  width: 100%;
}
.rp-list-main-wz-line {
  float: none;
  width: 100%;
  padding: 0 0 5px 0;
}
.rp-list-main-wz-list-50 {
  width: 50%;
}
.print {
  position: fixed;
  right: 20px;
  top: 20px;
}
</style>
